<template>
  <t-page>
    <template #header>
      <div style="width: 100%; display: flex; justify-content: space-between">
        <div>
          <h3>{{ $t('pages.registration_code.heading') }}</h3>
          <br>
          <p v-if="showTagline" v-html="$t('pages.registration_code.lead')" />
        </div>
        <div>
          <a :href="rejectionMailExampleUrl" target="_blank">
            <t-button type="simple">{{
              $t('pages.registration_code.actions.example_email')
            }}</t-button>
          </a>
          <a :href="applicantFlyerUrl" target="_blank">
            <t-button type="simple">{{
              $t('pages.registration_code.actions.flyer_for_applicant')
            }}</t-button>
          </a>
          <NuxtLink v-if="canCreate" :to="{ name: $RouteEnum.REGISTRATION_CODES.CREATE }">
            <t-button>{{ $t('pages.registration_code.actions.create') }}</t-button>
          </NuxtLink>
        </div>
      </div>
    </template>
    <t-grid>
      <t-grid-cell>
        <index-table :query="query" :fields="fields" :filters="filters" />
      </t-grid-cell>
    </t-grid>
  </t-page>
</template>

<script>
import Vue from 'vue';
import UserTypeEnum from '~/enums/UserTypeEnum';
import IndexTable from '~/pages/shared/registration-codes/partials/IndexTable';

export default Vue.extend({

  components: { IndexTable },
  props: {
    organization: {
      type: Object,
      required: false,
      default: () => undefined,
    },

    filters: {
      type: Array,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },

    query: {
      type: Function,
      required: true,
    },
  },

  computed: {
    canCreate() {
      return this.$ability.can('create', 'RegistrationCode');
    },

    showTagline() {
      return this.$auth.me.user.type === UserTypeEnum.EMPLOYEE;
    },

    tenantSlug() {
      return this.$store.state.tenant.tenant.slug;
    },

    applicantFlyerUrl() {
      return `https://ausbildungsradar.de/${this.tenantSlug}/bewerber-flyer`;
    },

    rejectionMailExampleUrl() {
      return `https://ausbildungsradar.de/${this.tenantSlug}/bewerber-absage`;
    },
  },
});
</script>
