import Model from '~/models/Model';

export default class RegistrationCode extends Model {
  resource() {
    return 'v1/registration-codes';
  }

  async $findByToken(token: string) {
    // @ts-ignore
    const endpoint = this.endpoint();

    this.params({ token });

    const response = await this.request({
      method: 'GET',
      url: `${endpoint}/by-token${this._builder.query()}`,
    });

    return response?.data?.data;
  }

  static $findByToken(token: string) {
    const self = new this();

    return self.$findByToken(token);
  }
}
