<template>
  <div>
    <span>{{ formattedDate }}</span>
  </div>
</template>
<script>
export default {
  props: {
    date: {
      type: String,
      required: true,
    },
  },

  computed: {
    formattedDate() {
      return this.$dayjs(this.date).format('L');
    },
  },
};
</script>
