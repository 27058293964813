<template>
  <t-tag :type="type" soft>
    {{ label }}
  </t-tag>
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      required: true,
    },
  },

  computed: {
    label() {
      return this.state === 'active'
        ? this.$t('global.statuses.active')
        : this.$t('global.statuses.inactive');
    },

    type() {
      return this.state === 'active' ? 'success' : 'warning';
    },
  },
};
</script>
