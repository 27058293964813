<template>
  <div class="empty-state content--centered">
    <dashboard-image :name="image" />
    <h1 class="mt-5 text--centered" v-html="title" />
    <p class="mt-3 text--grey text--centered" v-html="subtitle" />
    <slot />
  </div>
</template>
<script>
import DashboardImage from '~/components/DashboardImage';

export default {
  components: {
    DashboardImage,
  },

  props: {
    image: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.empty-state {
  max-width: 600px;
  padding: $s-16 0;
  margin: 0 auto;

  p {
    font-size: $font-size-lg;
    margin-bottom: $s-8;
  }
}
</style>
